import { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { useSubscription } from '@apollo/client'

import { User_users } from '../hasura/graphQlQueries/types/User'

//@ts-ignore
import { usePrevious } from './usePrevious'

const CONSULTATIONS_UPDATED = (organization_id?: number) => gql`
  subscription OnConsultationsUpdated {
    consultations_aggregate(where: {case: {dicom_server: {organization_id: {_eq: ${organization_id}}}}}) {
      aggregate {
        count
      }
    }
  }
`

export const useConsultationsCount = (updateCallback: (count: number) => void, user: User_users) => {
  const [consultationsCount, setConsultationsCount] = useState<number | undefined>(undefined)
  const consultationsSubscription = useSubscription(CONSULTATIONS_UPDATED(user.organization.id))
  const previousConsultationsCount = usePrevious(consultationsCount)

  useEffect(() => {
    const count = consultationsSubscription.data?.consultations_aggregate?.aggregate?.count
    const update = count >= (previousConsultationsCount || 0)
    if (update) updateCallback(count)
    setConsultationsCount(count)
  }, [user, consultationsSubscription.data?.consultations_aggregate?.aggregate?.count])
}
