import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchUserInfoAction, usersSelector, UsersState } from '../../hasura/slices/users'

export function UserInfo() {
  const dispatch = useDispatch()

  const { accessToken, userInfo, user }: UsersState = useSelector(usersSelector)

  useEffect(() => {
    if (!accessToken || !user) return

    dispatch(fetchUserInfoAction(accessToken, user.organization.id))
  }, [accessToken, user])

  if (!userInfo) return null

  const numberOfPatients = userInfo.cases_aggregate.aggregate?.count
  const numberPending = userInfo.requested_consultations_aggregate.aggregate?.count || 0

  return (
    <div className="flex-center text-m position-relative py-2">
      <div className="pe-auto m-0 d-flex text--gray6 justify-content-end">
        <div onClick={() => navigate('/invoices')} className="d-flex m-0 hover-underline pointer">
          <span className="semibold">{numberOfPatients}</span> <span className="ml-1">case{numberOfPatients === 1 ? '' : 's'}</span>
        </div>

        <span className="mx-2">/</span>

        <div onClick={() => navigate('/invoices')} className="d-flex m-0 hover-underline pointer">
          <span className="semibold">{userInfo.completed_consultations_aggregate.aggregate?.count}</span>{' '}
          <span className="ml-1">consults</span>
        </div>

        <span className="mx-2">/</span>

        <div className={`d-flex m-0 ${numberPending > 0 ? 'text--primary' : ''}`}>
          <span className="semibold">{numberPending}</span> <span className="ml-1">pending</span>
        </div>
      </div>

      <p style={{ top: '-12px' }} className="position-absolute top-0 left-0 bold m-0 text--gray5 ls-sm text-xxs py-1">
        THIS MONTH
      </p>
    </div>
  )
}
