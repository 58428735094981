import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, Spinner } from 'reactstrap'
import { loadStripe } from '@stripe/stripe-js'
import { useDispatch, useSelector } from 'react-redux'

import CONFIG from '../config'
import { billingSelector, BillingState, createCheckoutSessionAction } from '../hasura/slices/billing'
import { exitedEnableBillingModalAction, usersSelector, UsersState } from '../hasura/slices/users'

// @ts-ignore
import closeIcon from '../lib/images/close.png'
// @ts-ignore
import logo from '../lib/images/logo.png'

export default function BillingModal() {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  const { user }: UsersState = useSelector(usersSelector)
  const { checkoutSessionId }: BillingState = useSelector(billingSelector)

  const openCheckout = async (ai: boolean) => {
    setIsLoading(true)
    const id = user?.related_accounts?.stripe_profile?.id
    if (id) dispatch(createCheckoutSessionAction(id, ai))
  }

  useEffect(() => {
    ;(async () => {
      if (!checkoutSessionId) return

      const stripe = await loadStripe(CONFIG.STRIPE_KEY)
      if (!stripe) return

      stripe.redirectToCheckout({ sessionId: checkoutSessionId })
      setIsLoading(false)
    })()
  }, [checkoutSessionId])

  return (
    <Modal toggle={() => dispatch(exitedEnableBillingModalAction())} isOpen size="lg">
      <ModalBody className="p-5 text-center position-relative">
        <img
          onClick={() => dispatch(exitedEnableBillingModalAction())}
          className="m-2 position-absolute icon-m"
          src={closeIcon}
          style={{ top: '10px', right: '10px' }}
        />

        <h1 className="bold text-primary m-0">
          Welcome To
          <br />
          Radimal!
        </h1>

        <img className="my-3" style={{ height: '100px', width: 'auto' }} src={logo} />

        <p className="my-3 text-l">
          Thanks for joining for Radimal. You have unlocked free AI reports for your practice. To request full consultations please
          enter your billing info.
          <br />
          <br />
          Try our new features and let us know what you think!
        </p>

        {isLoading ? (
          <Spinner color="primary" />
        ) : (
          <div>
            <Button className="bold my-3" size="lg" color="primary" onClick={() => openCheckout(true)}>
              Enable AI + Full Consult
            </Button>

            <p role="button" className="m-0 text-m underline" onClick={() => openCheckout(false)}>
              Just enable Full Consult On Demand
            </p>
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}
