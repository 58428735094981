import { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { useSubscription } from '@apollo/client'

//@ts-ignore
import { usePrevious } from './usePrevious'
import { User_users } from '../hasura/graphQlQueries/types/User'

const CASES_UPDATED = (organization_id: number) => gql`
  subscription OnCasesUpdated {
    cases_aggregate(where: {dicom_server: {organization_id: {_eq: ${organization_id}}}}) {
      aggregate {
        count
      }
    }
  }
`

export const useCasesCount = (caseCreatedCallback: (count: number, dicomSetup: boolean) => void, user: User_users) => {
  const [casesCount, setCasesCount] = useState<number | undefined>(undefined)

  const casesSubscription = useSubscription(CASES_UPDATED(user.organization.id))
  const previousCasesCount = usePrevious(casesCount)

  useEffect(() => {
    const count = casesSubscription.data?.cases_aggregate?.aggregate?.count
    const caseCreated = previousCasesCount !== undefined && count > previousCasesCount!

    const dicomSetup = (user?.organization?.dicom_servers || []).length > 0
    if (caseCreated) caseCreatedCallback(count, dicomSetup)
    setCasesCount(count)
  }, [user, casesSubscription.data?.cases_aggregate?.aggregate?.count])
}
