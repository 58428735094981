import React, { useState } from 'react'
import { Button } from 'reactstrap'

interface Props {
  link: string
  prompt?: string
}

export default function CopyableLink(props: Props) {
  const [copiedReferralLink, setCopiedReferralLink] = useState(false)

  const copyReferralLink = () => {
    navigator.clipboard.writeText(`https://${props.link}`)
    setCopiedReferralLink(true)
  }

  return (
    <div>
      {props.prompt && <p className="semibold text-m text--gray7 mb-2 mr-3 nowrap">{props.prompt}</p>}

      <div className="d-flex align-items-center justify-content-start">
        <p className="py-1 h-100 px-2 m-0 rounded" style={{ border: '1px dotted gray' }}>
          {props.link}
        </p>

        <div className="d-flex align-items-end">
          <Button size="sm" onClick={copyReferralLink} outline color="primary" className="ml-1">
            Copy
          </Button>

          {copiedReferralLink && <p className="text-s semibold text--success mb-0 ml-2">Copied!</p>}
        </div>
      </div>
    </div>
  )
}
