import { Consultations_consultations } from '../hasura/graphQlQueries/types/Consultations'
import { User_users } from '../hasura/graphQlQueries/types/User'
import { VetStatus } from '../hasura/slices/users'

export const shouldSendStatOffAlert = (consultations: Consultations_consultations[], vetStatus?: VetStatus, user?: User_users) => {
  if (!vetStatus || !user) return

  const onlySpecialistWithStat = vetStatus.stat_plus_vet_ids.filter((id) => id !== user.id).length === 0
  const statCaseExists = consultations.filter((consultation) => consultation.stat_type).length > 0
  return onlySpecialistWithStat && statCaseExists
}
