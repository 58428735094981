import styled from 'styled-components'

import { palette } from '../../lib/cssHelpers'
import { shade } from '../../lib/helpers'

interface Props {
  width?: string
}

export default styled.div`
  height: 2px;
  width: ${(p: Props) => p.width};
  margin: 18px 0;
  background-color: ${shade(palette.secondary, 0.5)};
`
