import { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { useSubscription } from '@apollo/client'

import { User_users } from '../hasura/graphQlQueries/types/User'

//@ts-ignore
import { usePrevious } from './usePrevious'

const RADIMAL_CONSULTATIONS_UPDATED = gql`
  subscription OnConsultationsUpdated2($enterprise_id: Int, $claimed_ids: [Int!]!) {
    consultations_aggregate(
      where: {
        status: { _eq: "requested" }
        _or: [{ overflow: { _eq: true } }, { case: { dicom_server: { organization: { enterprise_id: { _eq: $enterprise_id } } } } }]
        id: { _nin: $claimed_ids }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

const CONSULTATIONS_UPDATED = gql`
  subscription OnConsultationsUpdated($enterprise_id: Int, $claimed_ids: [Int!]!) {
    consultations_aggregate(
      where: {
        status: { _eq: "requested" }
        case: { dicom_server: { organization: { enterprise_id: { _eq: $enterprise_id } } } }
        id: { _nin: $claimed_ids }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const useTotalConsultationsCount = (
  consultationRequestedCallback: (count: number, previousCount?: number) => void,
  user: User_users | undefined,
  claimed_ids: number[] = []
) => {
  const [consultationsCount, setConsultationsCount] = useState<number | undefined>(undefined)
  // if the user is from Radimal then also search for consultations where overflow is true
  const subscription = user?.organization?.enterprise?.id === 1 ? RADIMAL_CONSULTATIONS_UPDATED : CONSULTATIONS_UPDATED
  const consultationsSubscription = useSubscription(subscription, {
    variables: { enterprise_id: user?.organization?.enterprise.id, claimed_ids },
  })
  const previousConsultationsCount = usePrevious(consultationsCount)

  useEffect(() => {
    const count = consultationsSubscription.data?.consultations_aggregate?.aggregate?.count
    consultationRequestedCallback(count, previousConsultationsCount)
    setConsultationsCount(count)
  }, [user, consultationsSubscription.data?.consultations_aggregate?.aggregate?.count])
}
