import React, { useEffect, useState } from 'react'
import { Badge, Button, Modal, ModalBody, Spinner } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import CopyableLink from '../common/CopyableLink'
import { Input } from '../common/Input'
import { NotificationId, setNotificationAction } from '../../hasura/slices/notifications'
import { OrganizationEvent } from '../../lib/organizationEventTypes'
import { QueryName } from '../../hasura/queryNames'
import { insertOrganizationEventForUserAction } from '../../hasura/slices/organizations'
import { pluralize, trackHotjarEvent, validEmail } from '../../lib/helpers'

import {
  sendReferralsAction,
  setDisplayReferralModalAction,
  slackMessageAction,
  usersSelector,
  UsersState,
} from '../../hasura/slices/users'

// @ts-ignore
import closeIcon from '../../lib/images/close.png'

const parseEmailString = (s?: string) =>
  s
    ?.split(/,|;/)
    .map((s) => s.trim())
    .filter(validEmail) || []

export default function ReferralModal() {
  const dispatch = useDispatch()

  const { accessToken, user, isQuerying }: UsersState = useSelector(usersSelector)

  const [emails, setEmails] = useState<string | undefined>()

  const referralLink = `vet.radimal.ai/referral?s=${user?.organization.display_name.replace(/ /g, '-').toLowerCase()}`

  const emailsArray = parseEmailString(emails)

  useEffect(() => {
    if (!user || !accessToken) return

    trackHotjarEvent('opened_referral_modal')
    dispatch(insertOrganizationEventForUserAction(accessToken, OrganizationEvent.OpenedInviteYourFriends, user.organization.id))
    localStorage.setItem('opened_referral_modal', 'true')
  }, [user, accessToken])

  const sendReferrals = async () => {
    await dispatch(sendReferralsAction(user!.organization.display_name, emailsArray))
    setEmails(undefined)
    dispatch(slackMessageAction('users', `${user?.organization.display_name} referred ${emails}.`))
    dispatch(setNotificationAction(NotificationId.ReferralsSent))
  }

  const close = () => {
    dispatch(setDisplayReferralModalAction(false))
  }

  return (
    <Modal className="min-width-600px" toggle={close} isOpen size="lg">
      <ModalBody className="p-5 text-center position-relative">
        <img onClick={close} className="m-2 position-absolute icon-m" src={closeIcon} style={{ top: '10px', right: '10px' }} />

        <img
          className="mx-auto border border--primary rounded border-width-2px"
          src="https://radimal-images.s3.us-east-2.amazonaws.com/referral-bg.jpeg"
          style={{ width: 'auto', height: '250px' }}
        />

        <div className="my-5">
          <h3 className="bold text-primary mb-0">Invite Your Friends</h3>

          <p className="text-l mt-2 mb-0">
            You (and your friend) will each get <b>3 FREE</b> reports when they request their first case.
          </p>

          <p className="text-l mt-2 mb-0">
            Also, we will donate $100 to{' '}
            <a target="_blank" href="https://www.nomv.org/">
              Not One More Vet
            </a>
            , on your behalf.
          </p>
        </div>

        <div className="d-flex flex-column align-items-center border border--secondary rounded mt-4 p-4">
          <div className="d-flex w-100">
            <div className="position-relative flex-grow">
              <Input
                value={emails || ''}
                onChange={(e) => setEmails(e.target.value)}
                className="w-100"
                placeholder="Enter email addresses"
              />

              {emailsArray.length > 0 && (
                <Badge style={{ bottom: '-20px' }} className="position-absolute right-0">
                  {pluralize('recipient', emailsArray.length)}
                </Badge>
              )}
            </div>

            <Button
              onClick={sendReferrals}
              color="primary"
              disabled={isQuerying[QueryName.SendReferrals] || parseEmailString(emails)?.length === 0}
              className="ml-1"
            >
              {isQuerying[QueryName.SendReferrals] ? <Spinner size="sm" /> : 'Send Invites'}
            </Button>
          </div>

          <p className="text--gray8 my-2">or</p>

          <div className="flex-center">
            <CopyableLink prompt="Share Your Link:" link={referralLink} />
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
