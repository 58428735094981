import React, { useEffect, useState } from 'react'
import TextTransition, { presets } from 'react-text-transition'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'
import { Tooltip } from 'reactstrap'
import { navigate } from 'gatsby'
import { useSelector } from 'react-redux'

import { User_users } from '../../hasura/graphQlQueries/types/User'
import { consultationsSelector, ConsultationsState } from '../../hasura/slices/consultations'
import { emojiFor, pluralize, speciesFor } from '../../lib/helpers'
import { usePrevious } from '../../hooks/usePrevious'
import { usersSelector, UsersState } from '../../hasura/slices/users'

interface Props {
  irxMode?: boolean
}

const getEarnings = (user: User_users, timeframe: string) => {
  // @ts-ignore
  const consultationPayAmount = user[`${timeframe}_total`].aggregate?.sum?.receiving_vet_pay_amount || 0
  // @ts-ignore
  const trainingIterationsPayAmount = user[`training_iterations_${timeframe}`].aggregate.sum.pay_amount
  return `$${(consultationPayAmount + trainingIterationsPayAmount).toLocaleString('en', { useGrouping: true })}`
}

export function VetInfo(props: Props) {
  const { user, vetStatus }: UsersState = useSelector(usersSelector)
  const { consultations }: ConsultationsState = useSelector(consultationsSelector)

  const [displayTooltip, setDisplayTooltip] = useState(false)
  const [earningsUpdating, setEarningsUpdating] = useState(false)
  const [thisMonthEarnings, setThisMonthEarnings] = useState<string | undefined>()
  const [thisWeekEarnings, setThisWeekEarnings] = useState<string | undefined>()
  const [todayEarnings, setTodayEarnings] = useState<string | undefined>()

  const previousUser: any = usePrevious(user)

  useEffect(() => {
    if (!user || (previousUser && isEqual(getEarnings(user, 'daily'), getEarnings(previousUser, 'daily')))) return

    if (todayEarnings) setEarningsUpdating(true)

    setTodayEarnings(getEarnings(user, 'daily'))
    setThisWeekEarnings(getEarnings(user, 'weekly'))
    setThisMonthEarnings(getEarnings(user, 'monthly'))

    setTimeout(() => setEarningsUpdating(false), 3500)
  }, [user, previousUser])

  const { irxMode } = props

  const claimedCount = consultations.filter((c) => vetStatus?.my_locked_consultation_ids.includes(c.id)).length

  return (
    <div className={`single-line pe-auto flex-center text-m ${irxMode ? 'text-dark-bg' : 'text--gray6'}`}>
      <div onMouseLeave={() => setDisplayTooltip(false)} onMouseOver={() => setDisplayTooltip(true)}>
        <p id="cases-claimed" className="hide-mobile m-0 mr-4">
          {claimedCount} claimed
        </p>

        {displayTooltip && claimedCount > 0 && (
          <Tooltip
            style={{ maxWidth: '400px', width: 'fit-content' }}
            arrowClassName="d-none"
            fade={false}
            innerClassName="border bg--white text--black p-2 text-left width-300px"
            isOpen
            placement="top"
            target="cases-claimed"
          >
            {sortBy(consultations, 'case.patient.display_name')
              .filter((c) => vetStatus?.my_locked_consultation_ids.includes(c.id))
              .map((c) => (
                <p
                  className="text-m m-0 regular pointer p-1"
                  key={c.id}
                  onClick={() => window.location.assign(`${window.location.origin}/consultations?i=${c.id}&s=claim-list`)}
                >
                  <span className="text-l">{emojiFor(speciesFor(c.case.patient.species))}</span>
                  <span className="hover-underline">
                    {c.case.patient.display_name} ({pluralize('image', c.case.medical_images.length)})
                  </span>
                </p>
              ))}
          </Tooltip>
        )}
      </div>

      <div style={{ minWidth: '200px' }} className="m-0 d-flex justify-content-end">
        {todayEarnings && (
          <div onClick={() => navigate('/earnings?t=today')} role="button" className="d-flex m-0 hover-underline">
            <TextTransition
              className={`${earningsUpdating ? 'text--success' : ''} semibold transition-m`}
              text={todayEarnings}
              springConfig={presets.slow}
            />
            <span className="ml-1">today</span>
          </div>
        )}

        <span className="mx-1">/</span>

        {thisWeekEarnings && (
          <div onClick={() => navigate('/earnings?t=this-week')} role="button" className="d-flex m-0 hover-underline">
            <TextTransition
              className={`${earningsUpdating ? 'text--success' : ''} semibold transition-m`}
              text={thisWeekEarnings}
              springConfig={presets.slow}
            />
            <span className="ml-1">week</span>
          </div>
        )}

        <span className="mx-1">/</span>

        {thisMonthEarnings && (
          <div onClick={() => navigate('/earnings?t=this-month')} role="button" className="d-flex m-0 hover-underline">
            <TextTransition
              className={`${earningsUpdating ? 'text--success' : ''} semibold transition-m`}
              text={thisMonthEarnings}
              springConfig={presets.slow}
            />
            <span className="ml-1">month</span>
          </div>
        )}
      </div>
    </div>
  )
}
