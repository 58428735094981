import { useDispatch, useSelector } from 'react-redux'
import { useIntercom } from 'react-use-intercom'

import { NotificationId, setNotificationAction } from '../../hasura/slices/notifications'
import { UsersState, usersSelector, fetchUserAction } from '../../hasura/slices/users'
import { dispatchEvent } from '../../lib/helpers'
import { useCasesCount } from '../../hooks/useCasesCount'
import { useCompletedConsultationsCount } from '../../hooks/useCompletedConsultationsCount'
import { useConsultationsCount } from '../../hooks/useConsultationsCount'

export default () => {
  const dispatch = useDispatch()
  const { update } = useIntercom()

  const { accessToken, user }: UsersState = useSelector(usersSelector)

  useCasesCount((_: number, dicomSetup: boolean) => {
    if (!dicomSetup) dispatch(fetchUserAction(accessToken, user?.id))
    dispatch(setNotificationAction(NotificationId.CaseCreated))
    if (user?.audio_alerts_on) dispatchEvent('default-sound')
  }, user!)

  useCompletedConsultationsCount(() => {
    dispatch(setNotificationAction(NotificationId.ConsultationCompleted))
    if (user?.audio_alerts_on) dispatchEvent('default-sound')
  }, user!)

  useConsultationsCount((count: number) => {
    // timeout avoids race condition w/ trackEvent and automated Intercom message
    // - https://app.intercom.com/a/apps/j6btrtj7/outbound/chat/20984898
    setTimeout(() => {
      update({ customAttributes: { 'Number of Consultations': count } })
    }, 10 * 1000)
  }, user!)

  return null
}
