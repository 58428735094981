import { useDispatch, useSelector } from 'react-redux'

import { NotificationId, setNotificationAction } from '../../hasura/slices/notifications'
import { UsersState, usersSelector } from '../../hasura/slices/users'
import { dispatchEvent } from '../../lib/helpers'
import { useTotalConsultationsCount } from '../../hooks/useTotalConsultationsCount'

interface VetSubscriptionsProps {
  setConsultationsCount: React.Dispatch<React.SetStateAction<number>>
}

export default (props: VetSubscriptionsProps) => {
  const dispatch = useDispatch()

  const { user, vetStatus }: UsersState = useSelector(usersSelector)

  useTotalConsultationsCount(
    (count, previousCount) => {
      const consultationRequested = previousCount !== undefined && count > previousCount!

      if (consultationRequested) {
        dispatch(setNotificationAction(NotificationId.ConsultationRequested))
        if (user?.audio_alerts_on) dispatchEvent('default-sound')
      }

      props.setConsultationsCount(count)
    },
    user,
    vetStatus?.locked_consultations.map((l) => l.consultation_id) || []
  )

  return null
}
