import { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { useSubscription } from '@apollo/client'

import { User_users } from '../hasura/graphQlQueries/types/User'

// @ts-ignore
import { usePrevious } from './usePrevious'

const COMPLETED_CONSULTATIONS_UPDATED = (organization_id: number) => gql`
  subscription OnCompletedConsultationsUpdated {
    consultations_aggregate(
      where: { s3_url: { _is_null: false },
      _and: {case: {dicom_server: {organization_id: {_eq: ${organization_id}}}}}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const useCompletedConsultationsCount = (consultationCompletedCallback: () => void, user: User_users) => {
  const [completedConsultationsCount, setCompletedConsultationsCount] = useState<number | undefined>(undefined)
  const completedConsultationsSubscription = useSubscription(COMPLETED_CONSULTATIONS_UPDATED(user.organization.id))
  const previousCompletedConsultationsCount = usePrevious(completedConsultationsCount)

  useEffect(() => {
    const count = completedConsultationsSubscription.data?.consultations_aggregate?.aggregate?.count
    const consultationCompleted = previousCompletedConsultationsCount !== undefined && count > previousCompletedConsultationsCount!
    if (consultationCompleted) consultationCompletedCallback()
    setCompletedConsultationsCount(count)
  }, [user, completedConsultationsSubscription.data?.consultations_aggregate?.aggregate?.count])
}
