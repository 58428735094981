import React from 'react'
import { useSelector } from 'react-redux'

import HeaderBox from './HeaderBox'
import StatPlus from './../stat/StatPlus'
import { OrganizationSwitch } from './OrganizationSwitch'
import { UserInfo } from './UserInfo'
import { VetInfo } from './VetInfo'
import { VetStatCalendar } from './VetStatCalendar'
import { VetStatus } from './VetStatus'
import { isUser, isVet } from '../../lib/helpers'
import { usersSelector, UsersState } from '../../hasura/slices/users'
import { Alert } from 'reactstrap'

// @ts-ignore
import dangerIcon from '../../lib/images/danger-blue.svg'

interface Props {
  irxMode?: boolean
  handleLogout: () => void
}

export function Header(props: Props) {
  const { user, isRadimalEnterprise, role }: UsersState = useSelector(usersSelector)

  const { irxMode } = props

  if (!user) return null

  const hideSecondaryElements = ['/tagging/binary', 'ai-task'].some((s) => location.pathname.includes(s))

  return (
    <HeaderBox className="d-flex justify-content-between align-items-center" irxMode={irxMode}>
      <div className="d-flex align-items-center">
        <OrganizationSwitch irxMode={props.irxMode} />

        {!isUser(role) && !hideSecondaryElements && (
          <div className="flex-center">
            {isVet(role) && <StatPlus irxMode={irxMode} />}

            <VetStatus irxMode={irxMode} />

            {isVet(role) && isRadimalEnterprise && <VetStatCalendar irxMode={irxMode} />}
          </div>
        )}
      </div>

      {isVet(role) && !hideSecondaryElements && isRadimalEnterprise && <VetInfo irxMode={irxMode} />}

      {isUser(role) && user?.organization.dicom_servers.length > 0 && <UserInfo />}

      {user.organization.enterprise.in_evaluation && (
        <Alert fade={false} color="secondary" className="text-s max-width-600px width-fit-content p-2 ml-4 flex-center m-0">
          <img className="icon-xxxs mr-1" src={dangerIcon} />
          This enterprise is currently for evaluation purposes. Please let us know about any issues. No billing will take place until
          this enterprise is activated.
        </Alert>
      )}
    </HeaderBox>
  )
}
