import isEqual from 'lodash/isEqual'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  DownloadLink,
  notificationsSelector,
  NotificationsState,
  NOTIFICATION_TRANSITION_SECONDS,
  setDownloadLinksAction,
} from '../../hasura/slices/notifications'

import { getWindow } from '../../lib/helpers'
import { usePrevious } from '../../hooks/usePrevious'

// @ts-ignore
import exit from '../../lib/images/exit-white.svg'
// @ts-ignore
import download from '../../lib/images/download-white.svg'
// @ts-ignore
import checkmark from '../../lib/images/checkmark-green.svg'

export default function DownloadLinks() {
  const dispatch = useDispatch()

  const { downloadLinks }: NotificationsState = useSelector(notificationsSelector)

  const [opacity, setOpacity] = useState(0)
  const [downloaded, setDownloaded] = useState<DownloadLink[]>([])

  const previousDownloadLinks = usePrevious(downloadLinks)

  const pathname: string | undefined = getWindow(['location', 'pathname'])
  const renderLeft = pathname?.includes('consultations')

  useEffect(() => {
    if (!downloadLinks?.length) {
      setOpacity(0)
      setDownloaded([])
    } else {
      setOpacity(1)
    }
  }, [downloadLinks, previousDownloadLinks])

  return (
    <div
      className={`
        position-fixed m-0 text-white p-4 min-width-300px rounded bg--secondary7
        ${!downloadLinks?.length ? 'invisible' : ''}
      `}
      style={{
        bottom: '20px',
        right: renderLeft ? '' : '40px',
        left: renderLeft ? '40px' : '',
        opacity,
        transitionDuration: `${NOTIFICATION_TRANSITION_SECONDS}s`,
        transitionProperty: 'opacity',
        zIndex: 9999,
      }}
    >
      <div className="d-flex justify-content-between pointer">
        <h6 className="mb-2">Download Images</h6>

        <img onClick={() => dispatch(setDownloadLinksAction(undefined))} className="icon-xs" src={exit} />
      </div>

      <div>
        {downloadLinks?.map((d, idx) => (
          <div
            className="d-flex mt-2"
            onClick={() => {
              setDownloaded(downloaded.concat(d))
              window.open(d.url, '_blank')
            }}
          >
            <p className="text-s m-0 hover-underline pointer" key={idx}>
              {d.text}
            </p>

            <img className="ml-2 mr-4 icon-xs pointer" src={downloaded.some((d2) => isEqual(d, d2)) ? checkmark : download} />
          </div>
        ))}
      </div>
    </div>
  )
}
