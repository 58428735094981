import CONFIG from '../config'
import { hasWindow, isLocalhost } from '../lib/helpers'
;(function () {
  if (!hasWindow || isLocalhost()) return

  let urlFor = {
    'default-sound': 'sound1.mp3',
    'stat-sound': 'sound3.mp3',
  }

  function audioFor(url) {
    var audio = document.createElement('audio')
    audio.src = url
    audio.preload = 'auto'
    audio.style.display = 'none'
    document.body.appendChild(audio)
    return audio
  }

  function attachSound(type, audio) {
    window.addEventListener(type, () => {
      try {
        audio.currentTime = 0
        audio.load()
        audio.play()
      } catch (error) {
        console.log('ERROR playing', type, error)
      }
    })
  }

  for (const type in urlFor) {
    let url = CONFIG.AUDIO_URL + urlFor[type]
    let audio = audioFor(url)
    attachSound(type, audio)
  }
})()
