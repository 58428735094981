import DatePicker from 'react-datepicker'
import FullCalendar from '@fullcalendar/react'
import React, { useEffect, useState } from 'react'
import first from 'lodash/first'
import googleCalendarPlugin from '@fullcalendar/google-calendar'
import interactionPlugin from '@fullcalendar/interaction'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import timeGridPlugin from '@fullcalendar/timegrid'
import { Button, Spinner } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import 'react-datepicker/dist/react-datepicker.css'

import { QueryName } from '../../hasura/queryNames'
import { cleanDisplayName, OLOG } from '../../lib/helpers'

import {
  deleteVetStatCalendarEventAction,
  getVetStatCalendarAction,
  StatBooking,
  updateVetStatCalendarAction,
  usersSelector,
  UsersState,
} from '../../hasura/slices/users'

// @ts-ignore
import calendar from '../../lib/images/calendar.svg'
// @ts-ignore
import arrowLeftIcon from '../../lib/images/arrow-left.svg'

const isMe = (title: string, userDisplayName?: string) => title.startsWith(cleanDisplayName(userDisplayName || ''))

const renderEventContent = (eventInfo: any, userDisplayName?: string) => (
  <div className={`h-100 p-1 rounded ${isMe(eventInfo.event.title, userDisplayName) ? 'bg--primary' : 'bg--secondary'}`}>
    <p className="text-xxs bold m-0">{eventInfo.timeText}</p>
    <p className="text-s m-0">{eventInfo.event.title.replace(' <> Radimal', '')}</p>
  </div>
)

interface CalendarProps {
  clickedDate: (date: Date) => void
  deleteEvent: (publicId: string) => void
  clickedBookTime: (start?: Date) => void
  userDisplayName?: string
  statBookings?: StatBooking[]
}

const Calendar = React.memo(
  (props: CalendarProps) => (
    <FullCalendar
      allDaySlot={false}
      eventSourceFailure={(e: any) => OLOG(`CALENDAR FAILURE ${JSON.stringify(e)}`)}
      eventSourceSuccess={(e: any) => OLOG(`CALENDAR SUCCESS ${JSON.stringify(e)}`)}
      eventSources={[{ googleCalendarId: 'c_suo315h71kjqhq7evjjb01ffkg@group.calendar.google.com' }]}
      googleCalendarApiKey="AIzaSyBTd--c1N6rKacIzqkRE_U1GiRO6Ov4vCY"
      nowIndicator
      plugins={[timeGridPlugin, googleCalendarPlugin, interactionPlugin]}
      scrollTime="08:00:00"
      eventContent={(eventInfo: any) => renderEventContent(eventInfo, props.userDisplayName)}
      titleFormat={{ month: 'short', day: 'numeric' }}
      eventClick={(e) => {
        e.jsEvent.preventDefault()

        if (!isMe(e.event._def.title, props.userDisplayName) || !window.confirm('Are you sure you want to delete this appointment?')) {
          return
        }

        props.deleteEvent(e.event._def.publicId)
      }}
      customButtons={{
        add: {
          text: 'Book Time',
          click: () => {
            const start = first(first(document.getElementsByClassName('fc-toolbar-title'))?.innerHTML.split(' – '))
            const startDate = start ? new Date(`${start} ${new Date().getFullYear()}`) : undefined
            props.clickedBookTime(startDate)
          },
        },
      }}
      dateClick={(e: any) => props.clickedDate(e.date)}
      headerToolbar={{
        left: 'title',
        right: 'add today prev,next',
      }}
    />
  ),
  (prevProps: CalendarProps, nextProps: CalendarProps) => {
    return isEqual(prevProps.statBookings, nextProps.statBookings)
  }
)

const MAX_BLOCK_HOURS = 8

interface VetStatCalendarProps {
  irxMode?: boolean
}

export const VetStatCalendar = (props: VetStatCalendarProps) => {
  const { user, isQuerying, statBookings }: UsersState = useSelector(usersSelector)

  const dispatch = useDispatch()

  const [displayDatePicker, setDisplayDatePicker] = useState(false)
  const [start, setStart] = useState<Date | undefined>()
  const [end, setEnd] = useState<Date | undefined>()
  const [displayCalendar, setDisplayCalendar] = useState(false)

  const myNextStatBooking = statBookings
    ?.filter((s) => s.email === user?.email && moment(s.start).isAfter(moment()))
    .map((s) => moment(s.start))
    .sort()[0]

  const myCurrentBooking = statBookings?.find(
    (s) => s.email === user?.email && moment(s.start).isBefore(moment()) && moment(s.end).isAfter(moment())
  )

  useEffect(() => {
    dispatch(getVetStatCalendarAction())
  }, [])

  useEffect(() => {
    setEnd(start)
  }, [start])

  const handleBooking = async () => {
    await dispatch(
      updateVetStatCalendarAction(
        user!.email,
        cleanDisplayName(user!.display_name),
        user!.id,
        moment(start!).utc().format(),
        moment(end!).utc().format()
      )
    )
    goBackToCalendar()
  }

  const goBackToCalendar = () => {
    setDisplayDatePicker(false)
    setStart(undefined)
    setEnd(undefined)
  }

  const closeCalendar = () => {
    goBackToCalendar()
    setDisplayCalendar(false)
  }

  return (
    <div className="hide-mobile ml-4 pe-auto">
      <div onClick={() => setDisplayCalendar(true)} className="flex-center pointer">
        <img className="icon-m pointer" src={calendar} />

        <p className={`single-line hover-underline text-m mb-0 ml-2 ${props.irxMode ? 'text-dark-bg' : 'gray8'}`}>
          {myCurrentBooking
            ? `done ${moment(myCurrentBooking.end).fromNow()}`
            : myNextStatBooking
            ? `next appt. ${myNextStatBooking.fromNow()}`
            : 'no appts'}
        </p>
      </div>

      {displayCalendar && (
        <div className="position-fixed flex-center vw-100 vh-100 left-0 top-0 z-max-1">
          <div
            onClick={closeCalendar}
            className="position-absolute vw-100 vh-100 left-0 top-0 pe-auto"
            style={{ backgroundColor: '#000000', opacity: 0.5, zIndex: 0 }}
          />

          <div
            style={{ maxHeight: 'calc(100% - 100px)', maxWidth: 'calc(100% - 100px)', width: 'fit-content', height: 'fit-content' }}
            className="position-absolute"
          >
            {displayDatePicker ? (
              <div className="fc-media-screen-small d-flex flex-column justify-content-between align-items-center">
                <div className="w-100 d-flex justify-content-between">
                  <div className="flex-even">
                    <img onClick={goBackToCalendar} className="icon-m pointer" src={arrowLeftIcon} />
                  </div>

                  <div className="text-center">
                    <h3 className="bold mb-1">Book Time</h3>

                    <p className="text-xs text--gray7 mb-0">
                      (times shown in{' '}
                      {new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'short' }).substring(4)})
                    </p>
                  </div>

                  <div className="flex-even" />
                </div>

                <div>
                  <div className="d-flex mb-3">
                    <p style={{ minWidth: '50px' }} className="text-s semibold mr-2">
                      Start
                    </p>

                    <DatePicker
                      autoFocus
                      className="react-select-styles mw-300"
                      dateFormat="M/d h:mm a"
                      filterDate={(date: Date) => moment(date) >= moment().startOf('day')}
                      filterTime={(date: Date) => moment(date) >= moment()}
                      onChange={(date: Date) => setStart(date)}
                      selected={start}
                      showTimeSelect
                    />
                  </div>

                  <div className="d-flex">
                    <p style={{ minWidth: '50px' }} className="text-s semibold mr-2">
                      End
                    </p>

                    {start && (
                      <DatePicker
                        className="react-select-styles mw-300"
                        dateFormat="M/d h:mm a"
                        filterDate={(date: Date) =>
                          moment(date) >= moment(start).add('30', 'm').startOf('day') &&
                          moment(date) <= moment(start).add('4', 'h').startOf('day')
                        }
                        filterTime={(date: Date) =>
                          moment(date) >= moment(start).add('30', 'm') && moment(date) <= moment(start).add(MAX_BLOCK_HOURS, 'h')
                        }
                        onChange={(date: any) => setEnd(date)}
                        selected={end}
                        showTimeSelect
                      />
                    )}
                  </div>
                </div>

                <div className="w-100 flex-center flex-column">
                  <Button
                    className="px-5"
                    size="lg"
                    color="primary"
                    onClick={handleBooking}
                    disabled={!start || !end || start === end || isQuerying[QueryName.UpdateVetStatCalendar]}
                  >
                    {isQuerying[QueryName.UpdateVetStatCalendar] ? <Spinner size="sm" /> : 'Book'}
                  </Button>
                </div>
              </div>
            ) : (
              <Calendar
                clickedDate={(date: Date) => {
                  setStart(date)
                  setDisplayDatePicker(true)
                }}
                statBookings={statBookings}
                deleteEvent={(publicId: string) => dispatch(deleteVetStatCalendarEventAction(publicId))}
                clickedBookTime={(start) => {
                  if (start && start > new Date()) setStart(start)
                  setDisplayDatePicker(true)
                }}
                userDisplayName={user?.display_name}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}
