import React from 'react'
import capitalize from 'lodash/capitalize'
import { navigate } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'

import Logo from './../header/Logo'
import { Role } from '../../interfaces/Role'
import { isUser } from '../../lib/helpers'
import { updateUserRoleAction, usersSelector, UsersState } from '../../hasura/slices/users'

// @ts-ignore
import logo from '../../lib/images/logo.png'
// @ts-ignore
import logoBw from '../../lib/images/logo-bw.png'
// @ts-ignore
import arrowheadDown from '../../lib/images/arrowhead-down.png'
// @ts-ignore
import checkmark from '../../lib/images/checkmark-green.svg'

interface Props {
  irxMode?: boolean
}

const MAX_HEADER_LENGTH = 30

export function OrganizationSwitch(props: Props) {
  const dispatch = useDispatch()

  const [displayDropdown, setDisplayDropdown] = React.useState(false)
  const [isHoveringUserRoleId, setIsHoveringUserRoleId] = React.useState<number | undefined>()

  const { accessToken, user, role }: UsersState = useSelector(usersSelector)

  if (!accessToken || !user) return null

  const orgName = user.organization.display_name
  const displaySwitch = user.user_roles.length > 1 && user.user_roles.some((userRole) => userRole.role !== Role.Vet)

  return (
    <div className="d-flex position-relative align-items-center pointer pe-auto pr-4" role={props?.irxMode ? 'button' : ''}>
      <Logo
        onClick={() => navigate('/cases')}
        irxMode={props?.irxMode}
        src={user.organization.enterprise.logo_aws_s3_url || (props?.irxMode ? logoBw : logo)}
      />

      <h1
        onClick={() => navigate('/cases')}
        className={`bold m-0 text-xl single-line ${props?.irxMode ? 'text-white' : 'text-black'}`}
      >
        {isUser(role)
          ? orgName.slice(0, MAX_HEADER_LENGTH) + (orgName.length > MAX_HEADER_LENGTH ? '...' : '')
          : user.organization.enterprise.short_name}
      </h1>

      {displaySwitch && (
        <img
          style={{ filter: props.irxMode ? 'brightness(0) invert(1)' : '' }}
          onClick={() => setDisplayDropdown(!displayDropdown)}
          className="icon-s ml-2"
          src={arrowheadDown}
        />
      )}

      {displayDropdown && (
        <div style={{ top: '100%' }} className="border border-dark rounded bg--white mt-2 position-absolute z-max-2 min-width-200px">
          {user.user_roles.map((userRole) => {
            if (!userRole.enterprise) return null

            const isActive = user.organization.enterprise.id === userRole.enterprise.id && role === userRole.role
            return (
              <div
                key={userRole.id}
                style={{ backgroundColor: isHoveringUserRoleId === userRole.id ? '#f5f5f5' : 'transparent' }}
                onMouseOver={() => setIsHoveringUserRoleId(userRole.id)}
                onMouseLeave={() => setIsHoveringUserRoleId(undefined)}
                onClick={() => dispatch(updateUserRoleAction(user, userRole))}
                className={`px-3 py-2 d-flex align-items-center justify-content-between ${isActive ? 'pe-none' : ''}`}
              >
                <div>
                  <p className={`bold text-m m-0 ${isActive ? 'text--success' : 'text--gray6'}`}>{userRole.enterprise.short_name}</p>
                  <p className="text-xs test--gray6 m-0">
                    {capitalize(userRole.role.replace(/\badmin\b/g, 'super admin').replace('enterprise_', ''))}
                  </p>
                </div>

                {isActive && <img className="icon-xxs" src={checkmark} />}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
