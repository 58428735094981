import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntercom } from 'react-use-intercom'

import { consultationsSelector, ConsultationsState } from '../../hasura/slices/consultations'
import { dispatchEvent } from '../../lib/helpers'
import { shouldSendStatOffAlert } from '../../services/alerts'
import { usePrevious } from '../../hooks/usePrevious'

import {
  UsersState,
  sendStatOffAlertAction,
  slackMessageAction,
  updateVetStatusAction,
  usersSelector,
} from '../../hasura/slices/users'

interface Props {
  irxMode?: boolean
}

export default function StatPlus(props: Props) {
  const dispatch = useDispatch()
  const { trackEvent } = useIntercom()

  const { user, vetStatus, vetStatusesLoading }: UsersState = useSelector(usersSelector)
  const { allRequestedConsultations }: ConsultationsState = useSelector(consultationsSelector)

  useEffect(() => {
    if (!previousVetStatus) return

    // @ts-ignore
    const delta = vetStatus.my_stat_requests.length - previousVetStatus.my_stat_requests.length

    if (delta > 0 && user?.audio_alerts_on) dispatchEvent('stat-sound')
  }, [vetStatus])

  const previousVetStatus = usePrevious(vetStatus)

  if (!vetStatus) return null

  const statRequestAvailable = vetStatus.my_stat_requests.length > 0

  const statPlusOn = vetStatus.stat_plus_vet_ids.includes(user!.id)

  const toggleStatPlus = () => {
    if (!user) return

    dispatch(updateVetStatusAction(user, 'stat', statPlusOn ? 'off' : 'on'))

    if (statPlusOn && shouldSendStatOffAlert(allRequestedConsultations, vetStatus, user)) {
      dispatch(sendStatOffAlertAction(user))
    }
  }

  const acceptStatRequests = () => {
    if (!user) return

    dispatch(slackMessageAction('ai-report', `${user.display_name} claimed STAT via dashboard.`))
    vetStatus.my_stat_requests.forEach((id) => {
      trackEvent('stat-plus-claimed', { id })
      dispatch(updateVetStatusAction(user, 'consultation', String(id)))
    })
  }

  return (
    <div
      style={{ borderRadius: '50px', pointerEvents: vetStatusesLoading ? 'none' : 'auto', padding: '5px 15px' }}
      className={`${
        statRequestAvailable || statPlusOn ? 'bg-success text-white' : 'border border-danger text-muted'
      } justify-content-start d-flex align-items-center position-relative ml-5 shadow-sm`}
      role="button"
      onClick={() => {
        if (statRequestAvailable) {
          acceptStatRequests()
        } else {
          toggleStatPlus()
        }
      }}
    >
      <div
        style={{ width: '8px', height: '8px' }}
        className={`rounded-circle bg-${statRequestAvailable || statPlusOn ? 'white' : 'danger'} mr-1`}
      />

      <p className="m-0 text-m semibold single-line">
        {statRequestAvailable ? `New STAT+ case${vetStatus.my_stat_requests.length > 1 ? 's' : ''}` : 'STAT+'}
      </p>

      {statRequestAvailable && (
        <p
          style={{ bottom: '-18px' }}
          className={`font-italic position-absolute text-xxs text-center m-0 left-0 right-0 ${
            props.irxMode ? 'text-dark-bg' : 'gray6'
          }`}
        >
          Click to accept.
        </p>
      )}
    </div>
  )
}
