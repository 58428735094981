import './../index.scss'

import React, { useEffect, useState, useRef } from 'react'
// import Snowfall from 'react-snowfall'
import { Helmet } from 'react-helmet'
import { Spinner } from 'reactstrap'
import { navigate } from 'gatsby'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch, useSelector } from 'react-redux'
import { useIdleTimer } from 'react-idle-timer'
import { useIntercom } from 'react-use-intercom'

import '../../utils/audio'
import AuthenticationService from '../../services/authentication'
import Background from '../common/Background'
import BillingModal from '../BillingModal'
import CONFIG from '../../config'
import DownloadLinks from '../notifications/DownloadLinks'
import Notification from '../notifications/Notification'
import ReferralModal from '../referral/modal'
import UserSubscriptions from '../subscriptions/User'
import VetSubscriptions from '../subscriptions/Vet'
import { Header } from '../header/index'
import { Nav } from '../Nav'
import { Role } from '../../interfaces/Role'
import { fetchSpecialistPaymentsAction } from '../../hasura/slices/specialistPayments'
import { fetchVetStatusesAction, handleVetLogOff, notifyUsersStatAction, sendStatOffAlertAction } from '../../hasura/slices/users'
import { shouldSendStatOffAlert } from '../../services/alerts'
import { usePrevious } from '../../hooks/usePrevious'

import {
  createIntercomParams,
  deleteCookie,
  enterpriseIdsForUser,
  hotjarIdentify,
  isAdmin,
  isUser,
  isVet,
  parseIdFrom,
  setWithExpiry,
} from '../../lib/helpers'

import {
  ConsultationsState,
  consultationsSelector,
  fetchConditionsAction,
  fetchIncompleteConsultationAddendumsAction,
} from '../../hasura/slices/consultations'

import {
  notLoggedInAction,
  setAccessTokenAction,
  UsersState,
  usersSelector,
  fetchUserAction,
  updateVetStatusAction,
} from '../../hasura/slices/users'

interface Props {
  children: any
  irxMode?: boolean
  title?: string
}

const ADMIN_ONLY_ROUTES = ['/sales', '/metrics', '/feed', '/staff', '/clients']

// // @ts-ignore
// import heartImage from '../../lib/images/heart.svg'

// const heart = hasWindow ? document.createElement('img') : { src: undefined }
// heart.src = heartImage

const routeNotAllowed = (role: Role, pathname: string) =>
  location.hostname !== 'localhost' && !isAdmin(role) && ADMIN_ONLY_ROUTES.some((route) => pathname.startsWith(route))

export default function Layout(props: Props) {
  const { irxMode, children } = props

  const { update } = useIntercom()
  const dispatch = useDispatch()
  const { getAccessTokenSilently, logout } = useAuth0()

  const {
    accessToken,
    billingEnabled,
    displayReferralModal,
    exitedEnableBillingModal,
    role,
    user,
    vetStatus,
  }: UsersState = useSelector(usersSelector)

  const { allRequestedConsultations }: ConsultationsState = useSelector(consultationsSelector)

  const previousVetStatus = usePrevious(vetStatus)

  const [isGettingAccessToken, setIsGettingAccessToken] = useState(false)
  const [consultationsCount, setConsultationsCount] = useState(0)
  const fetchVetDataInterval = useRef<any | null>(null)

  // const [displayTheme, setDisplayTheme] = useState(true)
  // const [displayThemeTimeout, setDisplayThemeTimeout] = useState<any>()

  useEffect(() => {
    if (routeNotAllowed(role, location.pathname)) navigate('/cases')
  }, [role])

  useEffect(() => {
    if (
      isVet(role) &&
      vetStatus?.stat_available &&
      previousVetStatus !== undefined &&
      // @ts-ignore
      !previousVetStatus?.stat_available
    ) {
      const ids = vetStatus?.notify_stat_ids
      if (ids?.length) dispatch(notifyUsersStatAction(ids))
    }
  }, [vetStatus])

  /*
    User loading effects
  */

  useEffect(() => {
    ;(async () => {
      if (!getAccessTokenSilently) return

      try {
        setIsGettingAccessToken(true)
        const accessToken = await getAccessTokenSilently({ audience: CONFIG.AUTH0_AUDIENCE })
        dispatch(setAccessTokenAction(accessToken))
      } catch (error) {
        console.log("ERROR: can't get access token (auth0-react)")

        new AuthenticationService().checkSession((accessToken) => {
          if (accessToken) {
            dispatch(setAccessTokenAction(accessToken))
          } else {
            dispatch(notLoggedInAction())
          }
        })
      }

      setIsGettingAccessToken(false)
    })()
  }, [getAccessTokenSilently])

  useEffect(() => {
    if (accessToken && !user) {
      const userId = parseIdFrom(accessToken)
      if (CONFIG.IS_DEVELOPMENT && !CONFIG.DEVELOPMENT_USER_IDS.includes(userId || '')) {
        logout({ returnTo: window.location.origin })
        return
      }

      dispatch(fetchConditionsAction(accessToken))
      dispatch(fetchUserAction(accessToken, userId))
    }
  }, [accessToken, user])

  useEffect(() => {
    if (!user) return

    // poll vet status
    clearInterval(fetchVetDataInterval.current)
    const intervalMs = 1000 * (isVet(role) && !CONFIG.IS_DEVELOPMENT ? 5 : 20)
    fetchVetDataInterval.current = setInterval(fetchVetData, intervalMs)
    fetchVetData()

    if (isVet(role)) {
      setWithExpiry('vet-id', user!.id, 60 * 60 * 24)

      dispatch(
        fetchSpecialistPaymentsAction(
          accessToken,
          user.user_roles.map((r) => r.enterprise.id)
        )
      )
      dispatch(updateVetStatusAction(user, 'online', 'on'))
      // @ts-ignore
      window.intercomSettings = { app_id: CONFIG.INTERCOM_APP_ID, hide_default_launcher: true }
    }

    // analytics
    if (!CONFIG.IS_DEVELOPMENT) {
      update(createIntercomParams(user, role))
      hotjarIdentify(user, role)
    }

    return () => {
      clearInterval(fetchVetDataInterval.current)
    }
  }, [user])

  /*
    Idle polling - https://idletimer.dev/docs/api
  */

  const IDLE_MINUTES = 30
  const ACTION_THROTTLE_MINUTES = 1

  const onAction = (_: any) => {
    // setDisplayTheme(false)
    // clearTimeout(displayThemeTimeout)
    // setDisplayThemeTimeout(setTimeout(() => setDisplayTheme(true), 1000 * 10))

    if (!isVet(role) || !user) return

    dispatch(updateVetStatusAction(user, 'online', 'on'))
    if (vetStatus?.stat_plus_vet_ids.includes(user.id)) dispatch(updateVetStatusAction(user, 'stat', 'on'))
  }

  useIdleTimer({ onAction, timeout: 1000 * 60 * IDLE_MINUTES, eventsThrottle: 1000 * 60 * ACTION_THROTTLE_MINUTES })

  /* 
    Methods
  */

  const fetchVetData = () => {
    if (!user) return

    if (!isUser(role)) {
      dispatch(fetchIncompleteConsultationAddendumsAction(accessToken, enterpriseIdsForUser(user)))
    }

    dispatch(fetchVetStatusesAction(accessToken, user, role))
  }

  const handleLogout = () => {
    deleteCookie()
    if (isVet(role)) handleVetLogOff()
    if (user && vetStatus?.stat_plus_vet_ids.includes(user.id) && shouldSendStatOffAlert(allRequestedConsultations, vetStatus, user)) {
      dispatch(sendStatOffAlertAction(user))
    }
    logout({ returnTo: window.location.origin })
    new AuthenticationService().deleteAccessTokenCookie()
  }

  const dark = irxMode || (isVet(role) && user?.dark_mode_on && location.pathname.includes('/cases'))

  return (
    <div className="vw-100 min-vh-100 position-absolute">
      <Helmet>
        <meta charSet="utf-8" />

        <title>
          {consultationsCount > 0 ? `(${consultationsCount}) ` : ''}
          {props.title || 'Radimal'}
        </title>
      </Helmet>

      {user && isUser(role) && <UserSubscriptions />}

      {isVet(role) && <VetSubscriptions setConsultationsCount={setConsultationsCount} />}

      <Background dark={dark} />

      {/* {!irxMode &&
        !getWindow(['location', 'pathname'])?.includes('request') &&
        !getWindow(['location', 'pathname'])?.includes('metrics') &&
        !getWindow(['location', 'pathname'])?.includes('earnings') &&
        !getWindow(['location', 'pathname'])?.includes('feed') &&
        displayTheme && <Snowfall />} */}

      {billingEnabled === false && !exitedEnableBillingModal && user?.organization.enable_billing_flow && <BillingModal />}

      <div>
        <div className="d-flex">
          {!irxMode && <Nav dark={dark} billingEnabled={billingEnabled === true} logout={handleLogout} />}

          <div className="flex-grow">
            <Header handleLogout={handleLogout} irxMode={dark} />

            {isGettingAccessToken || !user ? (
              <div className="vh-100 vw-100 d-flex align-items-center justify-content-center">
                <Spinner color="primary" style={{ width: '4rem', height: '4rem' }} />
              </div>
            ) : (
              children
            )}
          </div>
        </div>

        <Notification />

        <DownloadLinks />

        {displayReferralModal && <ReferralModal />}
      </div>
    </div>
  )
}
